import { ReactNode } from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  INLINES,
  Inline,
  Block,
  MARKS,
  Document,
} from '@contentful/rich-text-types';
import { BodyText } from 'components/UI/Brand/text';
import translate from 'translate';
import { HEADER_SIZE } from 'containers/Learn/Articles/utils/getHeader.util';
import { isDocument } from 'utils/isDocument';
import { Icon, LinkText } from 'components';
import { colors } from 'style/colors';
import CustomImage from './UI/CustomImage/CustomImage';
import { getHighestHeadingSize } from './UI/contentfulRenderer.util';
import LineBreak from './UI/LineBreak/LineBreak';
import { TBrand } from './UI/Brand/brand';
import { PrimaryButton } from './UI/Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

const options = (
  highestHeadingSize: HEADER_SIZE,
  highestDOMHeadingSize: HEADER_SIZE,
  fixedBrand: TBrand
) => ({
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => (
      <span className={styles.bold}>{text}</span>
    ),
    [MARKS.ITALIC]: (text: ReactNode) => (
      <span className={styles.italic}>{text}</span>
    ),
    [MARKS.UNDERLINE]: (text: ReactNode) => (
      <span className={styles.underline}>{text}</span>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: Inline | Block, children: ReactNode) => {
      if (
        Array.isArray(children) &&
        children.length === 1 &&
        children[0] === ''
      )
        return (
          <div aria-hidden="true" className={styles.paragraph}>
            <br aria-hidden="true" />
          </div>
        );
      return <BodyText className={styles.paragraph}>{children}</BodyText>;
    },
    [BLOCKS.HEADING_1]: (_: Inline | Block, children: ReactNode) =>
      getHighestHeadingSize({
        fixedBrand,
        headerBlock: HEADER_SIZE.H1,
        highestHeadingSize,
        highestDOMHeadingSize,
        children,
      }),
    [BLOCKS.HEADING_2]: (_: Inline | Block, children: ReactNode) =>
      getHighestHeadingSize({
        fixedBrand,
        headerBlock: HEADER_SIZE.H2,
        highestHeadingSize,
        highestDOMHeadingSize,
        children,
      }),
    [BLOCKS.HEADING_3]: (_: Inline | Block, children: ReactNode) =>
      getHighestHeadingSize({
        fixedBrand,
        headerBlock: HEADER_SIZE.H3,
        highestHeadingSize,
        highestDOMHeadingSize,
        children,
      }),
    [BLOCKS.HEADING_4]: (_: Inline | Block, children: ReactNode) =>
      getHighestHeadingSize({
        fixedBrand,
        headerBlock: HEADER_SIZE.H4,
        highestHeadingSize,
        highestDOMHeadingSize,
        children,
      }),
    [BLOCKS.HEADING_5]: (_: Inline | Block, children: ReactNode) =>
      getHighestHeadingSize({
        fixedBrand,
        headerBlock: HEADER_SIZE.H5,
        highestHeadingSize,
        highestDOMHeadingSize,
        children,
      }),
    [BLOCKS.UL_LIST]: (_: Inline | Block, children: ReactNode) => (
      <ul className={styles.list}>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (_: Inline | Block, children: ReactNode) => (
      <ol className={styles.list}>{children}</ol>
    ),
    [BLOCKS.LIST_ITEM]: (_: Inline | Block, children: ReactNode) => (
      <li className={styles.listItem}>{children}</li>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node: Inline | Block) => {
      if (!node.data.target?.jObject) {
        return null;
      }

      const { title, type, hyperlink, sys } = node.data.target.jObject;
      if (sys?.contentType?.sys?.type !== 'Link' && type !== 'video')
        return <></>;

      return (
        <div className={styles.videoContainer}>
          <iframe
            allow="fullscreen"
            className={styles.responsiveIframe}
            id={sys.id}
            src={hyperlink}
            title={title}
            height="360"
            width="640"
          />
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Inline | Block) => {
      const {
        description,
        title,
        file: {
          contentType,
          url,
          details: { image },
        },
      } = node.data.target;

      enum ContentType {
        SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        PNG = 'image/png',
        JPEG = 'image/jpeg',
      }

      switch (contentType) {
        case ContentType.SPREADSHEET:
          return (
            <PrimaryButton to={url} style={{ display: 'flex', gap: 16 }}>
              {title || translate('General_Download_Assets')}
              <Icon
                height={20}
                width={20}
                iconType="download"
                color={colors.shared.white}
              />
            </PrimaryButton>
          );

        case ContentType.JPEG:
        case ContentType.PNG:
          return (
            <CustomImage
              alt={description || title}
              src={url}
              height={image?.height}
              width={image?.width}
              isPresentation={!description || !title}
            />
          );

        default:
          return null;
      }
    },
    [BLOCKS.HR]: () => <LineBreak />,
    [INLINES.ASSET_HYPERLINK]: (node: Inline | Block, children: ReactNode) => (
      <LinkText href={node?.data?.target?.file?.url} download>
        {children}
      </LinkText>
    ),
    [INLINES.HYPERLINK]: (node: Inline | Block, children: ReactNode) => (
      <LinkText href={node?.data?.uri}>{children}</LinkText>
    ),
  },
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={textSegment} />,
          textSegment,
        ],
        []
      ),
});

interface IContentfulRendererProps {
  children: Document;
  highestHeadingSize?: HEADER_SIZE;
  highestDOMHeadingSize?: HEADER_SIZE;
  fixedBrand?: TBrand;
}

const ContentfulRenderer = ({
  children,
  highestHeadingSize,
  highestDOMHeadingSize,
  fixedBrand = 'coastal',
}: IContentfulRendererProps) => (
  <div className={styles.contentGroup}>
    {isDocument(children)
      ? documentToReactComponents(
          children,
          options(
            highestHeadingSize,
            highestDOMHeadingSize,
            fixedBrand
          ) as Options
        )
      : children}
  </div>
);

export default ContentfulRenderer;
