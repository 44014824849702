import { KeyboardEvent, useState } from 'react';
import { Header3, Header4, BodyText } from 'components/UI/Brand/text';
import ContentfulRenderer from 'components/ContentfulRenderer';
import { track } from 'utils/analytics';
import translate from 'translate';
import { IItinerary } from 'interfaces/IItinerary';
import { FlatButton } from 'components/UI';
import clsx from 'clsx';
import { dangerousConverter } from 'utils/dangerousHTMLConvert';
import InfoListItem from './InfoListItem';
import styles from './styles.module.scss';

interface IInfoListProps {
  tourTtitle: string;
  itinerary: IItinerary[];
}

const { REACT_APP_COMPANY } = process.env;

const InfoList = ({ tourTtitle, itinerary }: IInfoListProps) => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [activeDay, setActiveDay] = useState(null);

  const onClickedBack = () => {
    track('Back to itinerary', { voyageName: tourTtitle });
    setIsShowDetails(!isShowDetails);
  };

  const onClickedPrevious = (previousDay: string) => {
    setActiveDay(previousDay);
    track('Previous day itinerary', {
      voyageName: tourTtitle,
      day: previousDay,
    });
  };

  const onClickedNext = (nextDay: string) => {
    setActiveDay(nextDay);
    track('Next day itinerary', {
      voyageName: tourTtitle,
      day: nextDay,
    });
  };

  const onDayClickedHandler = (item: IItinerary) => {
    setActiveDay(item.day);
    setIsShowDetails(!isShowDetails);
    track('See itinerary day', {
      voyageName: tourTtitle,
      day: item.day,
    });
  };

  const onKeyDownBack = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClickedBack();
    }
  };

  const onKeyDownNext = (event: KeyboardEvent, nextDay: string) => {
    if (event.key === 'Enter') {
      onClickedNext(nextDay);
    }
  };

  const onKeyDownPrevious = (event: KeyboardEvent, previousDay: string) => {
    if (event.key === 'Enter') {
      onClickedPrevious(previousDay);
    }
  };

  const getDetailsContent = () => {
    const dayData = itinerary.find(
      (itineraryItem) => itineraryItem.day === activeDay
    );
    const nextDayIndex = itinerary.indexOf(dayData) + 1;
    const nextDay = itinerary[nextDayIndex]?.day;
    const previousDayIndex = itinerary.indexOf(dayData) - 1;
    const previousDay = itinerary[previousDayIndex]?.day;

    return (
      <div className={styles.detailsRoot}>
        <div className={styles.right}>
          <FlatButton
            icon="arrowLeftShort"
            iconWidth={24}
            iconHeight={24}
            onClick={onClickedBack}
            onKeyDown={(e: KeyboardEvent) => onKeyDownBack(e)}
          >
            {translate('InfoList_BackFullItinerary')}
          </FlatButton>
          <div className={styles.contentCntr}>
            <Header3 marginBottom="three">{`${dayData.day} ${dayData.heading}`}</Header3>
            <ContentfulRenderer>{dayData.body}</ContentfulRenderer>
            {dayData.excursions && dayData.excursions.length > 0 && (
              <BodyText marginBottom="three" marginTop="five">
                {translate('Itinerary_WhatIncluded')}
              </BodyText>
            )}
            <div className={styles.includedCntr}>
              {dayData.excursions.map((exc) => (
                <div key={exc.code} className={styles.excursionContainer}>
                  <Header4>{exc?.title}</Header4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dangerousConverter(exc.description, 'Text'),
                    }}
                  />
                </div>
              ))}
            </div>
            <div className={styles.infoListButtonsCntr}>
              <div>
                {previousDay && (
                  <FlatButton
                    icon="arrowLeft"
                    iconPosition="left"
                    iconWidth={24}
                    iconHeight={24}
                    tabIndex={0}
                    onClick={() => {
                      onClickedNext(previousDay);
                    }}
                    onKeyDown={(event: KeyboardEvent) =>
                      onKeyDownPrevious(event, previousDay)
                    }
                    classes={clsx(styles.nextTo, {
                      [styles.hx]: REACT_APP_COMPANY === 'HX',
                    })}
                  >
                    {previousDay}
                  </FlatButton>
                )}
              </div>
              <div className={styles.infoListButtonRightCntr}>
                {nextDay && (
                  <FlatButton
                    icon="arrowRight"
                    iconPosition="right"
                    iconWidth={24}
                    iconHeight={24}
                    tabIndex={0}
                    onClick={() => {
                      onClickedNext(nextDay);
                    }}
                    onKeyDown={(event: KeyboardEvent) =>
                      onKeyDownNext(event, nextDay)
                    }
                    classes={clsx(styles.nextTo, {
                      [styles.hx]: REACT_APP_COMPANY === 'HX',
                    })}
                  >
                    {nextDay}
                  </FlatButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {isShowDetails ? (
        getDetailsContent()
      ) : (
        <div>
          <Header3>{translate('InfoList_Itinerary')}</Header3>
          {itinerary.map((item) => (
            <InfoListItem
              key={item.day}
              name={`${item.day} - ${item.location}`}
              setIsShowDetails={() => onDayClickedHandler(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default InfoList;
