import { useState } from 'react';
import { Header4 } from 'components/UI/Brand/text';
import NoAvailableExtraItem from 'components/BookingFunnel/Extras/NoAvailableExtraItem';
import ExpandableList from 'components/UI/ExpandableList/ExpandableList';
import ExtraItem from 'components/BookingFunnel/Extras/ExtraItem';
import { ExtraExcursionsItems } from 'components/BookingFunnel/Extras/ExtraExcursionsItems';
import {
  IExtraItemsData,
  IExtrasSectionData,
  IExtrasType,
} from 'interfaces/IExtras';
import {
  createExtrasItemData,
  getAmountOfBookedTravelers,
  getEmptyExtraMessage,
  isExtraAdded,
} from 'containers/Book/BookingProgress/BookingFunnel/Extras/utils/extras.util';
import { useAppSelector } from 'hooks/storeHooks';
import styles from './styles.module.scss';

interface IExtrasSectionProps {
  extraSection: IExtrasSectionData;
  onOpenAddExtraModal: (data: IExtraItemsData, isEdit: boolean) => void;
  onRemoveExtra: (id: string, title: string, type: string) => void;
  hasDeckSpace?: boolean;
  isCoastal?: boolean;
}

const ExtrasSection = ({
  extraSection,
  onOpenAddExtraModal,
  onRemoveExtra,
  hasDeckSpace,
  isCoastal,
}: IExtrasSectionProps) => {
  const amountOfExtraItemsShown = 3;
  const [numberOfViableExtraItems, setNumberOfViableExtraItems] = useState(
    amountOfExtraItemsShown
  );
  const { extrasData } = useAppSelector((state) => state.extras);

  const { ariaLabelledby, extras, title, extraType, caption } = extraSection;

  const isExtraSectionAvailable = !(
    extraType === IExtrasType.Excursion && hasDeckSpace
  );
  const { bookedCabins } = useAppSelector((state) => state.bookingFunnel);
  const isPetAvailable =
    bookedCabins.some((cabin) => cabin.cabinGrade === 'CUPO') || hasDeckSpace;

  const filteredExtras = isPetAvailable
    ? extras
    : extras.filter((extra) => extra.code !== 'PET');

  const getExpandableListElement = () => {
    if (extraType === IExtrasType.Excursion) {
      return (
        <ExtraExcursionsItems
          extras={extras}
          numberOfViableExtrasItems={numberOfViableExtraItems}
          onRemoveExtra={onRemoveExtra}
          caption={caption}
          onOpenAddExtraModal={onOpenAddExtraModal}
          key={'excursion-list'}
        />
      );
    }
    return filteredExtras
      .slice(0, numberOfViableExtraItems)
      .map((extraItem, index: number) => {
        const id = extraItem.addOnId || extraItem.mealId;

        const onRemoveExtraHandler = () =>
          onRemoveExtra(
            id,
            extraItem.title || extraItem.description,
            extraType
          );

        return (
          <li aria-labelledby={ariaLabelledby} key={id}>
            <ExtraItem
              index={1 + index}
              extraItem={createExtrasItemData(extraItem, extraType, caption)}
              onOpenAddExtraModal={onOpenAddExtraModal}
              isAdded={isExtraAdded(extrasData, id)}
              onRemoveExtra={onRemoveExtraHandler}
              amountOfBookedTravelers={getAmountOfBookedTravelers(
                extrasData,
                id
              )}
            />
          </li>
        );
      });
  };

  if (!isExtraSectionAvailable) return null;

  return (
    <fieldset className={styles.extrasSection}>
      <Header4 id={ariaLabelledby}>{title}</Header4>
      {!extras?.length ? (
        <NoAvailableExtraItem message={getEmptyExtraMessage(extraType)} />
      ) : (
        <ExpandableList
          className={styles.list}
          visibleItemsCount={numberOfViableExtraItems}
          setVisibleItemsCount={setNumberOfViableExtraItems}
          initialVisibleItemsCount={amountOfExtraItemsShown}
          childrenLength={extras.length}
          ariaLabelledby={ariaLabelledby}
          isCoastal={isCoastal}
        >
          {getExpandableListElement()}
        </ExpandableList>
      )}
    </fieldset>
  );
};

export default ExtrasSection;
