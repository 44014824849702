import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useAppDispatch } from 'hooks/storeHooks';
import TextInput, {
  TextInputChangeEvent,
} from 'components/UI/TextInput/TextInput';
import TermsModal from 'components/UI/Modals/TermsModal/TermsModal';
import inputValidator from 'utils/inputValidator';
import {
  fetchLegal,
  fetchValidAgentValues,
  createHeaderMessage,
} from 'store/actions';
import translate from 'translate';
import Dropdown from 'components/UI/Select/Dropdown';
import PhoneInput from 'components/UI/PhoneInput/PhoneInput';
import { Region } from 'utils/regions';
import { IRootState } from 'store/types/root';
import { IAgency, IProfileData } from 'interfaces/IProfile';
import LoadingGif from 'components/UI/Loading/LoadingGif';
import { formatPhoneNumber } from 'utils/phone';
import { useModal } from 'hooks/useModal';
import { FAILED } from 'utils/constants';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import { Checkbox } from 'components';
import styles from './styles.module.scss';

interface IInputBase {
  valid: boolean;
  value: string | boolean;
  touched: boolean;
}

interface IStringInput extends Omit<IInputBase, 'value'> {
  value: string;
}

interface IBoolInput extends Omit<IInputBase, 'value'> {
  value: boolean;
}

interface IRegisterUserDataProps {
  onSubmit: (agentData: IProfileData) => void;
  populateData: IProfileData;
  accountMigration: boolean;
  region: Region;
}

interface IInputs {
  firstName: IStringInput;
  lastName: IStringInput;
  phoneNumber: IStringInput;
  jobTitle: IStringInput;
  city: IStringInput;
  address: IStringInput;
  building: IStringInput;
  postalCode: IStringInput;
  country: IStringInput;
  state: IStringInput;
  expectedAmount: IStringInput;
  confirm: IBoolInput;
  hasNewsletterEnabled: IBoolInput;
}

const GetDefaultInputValues = (region: Region): IInputs => ({
  firstName: { value: '', valid: false, touched: false },
  lastName: { value: '', valid: false, touched: false },
  phoneNumber: { value: '', valid: false, touched: false },
  jobTitle: { value: '', valid: false, touched: false },
  city: { value: '', valid: false, touched: false },
  address: { value: '', valid: false, touched: false },
  building: { value: '', valid: false, touched: false },
  postalCode: { value: '', valid: false, touched: false },
  country: { value: '', valid: false, touched: false },
  state: { value: '', valid: false, touched: false },
  expectedAmount: {
    value: '',
    valid: region !== Region.Americas,
    touched: false,
  },
  confirm: { value: false, valid: false, touched: false },
  hasNewsletterEnabled: {
    value: region === Region.Americas,
    valid: true,
    touched: false,
  },
});

const RegisterUserData = ({
  onSubmit,
  populateData,
  accountMigration,
  region,
}: IRegisterUserDataProps) => {
  const dispatch = useAppDispatch();
  const { isModalVisible, showModal, hideModal, RenderModal } = useModal();

  const { flags: featureFlags } = useSelector(
    (state: IRootState) => state.feature
  );
  const { agentValidValues, agentValuesLoading } = useSelector(
    (state: IRootState) => state.onboarding
  );
  const { legal } = useSelector((state: IRootState) => state);

  const [inputs, setInputs] = useState(GetDefaultInputValues(region));
  const [isFormValid, setIsFormValid] = useState(false);
  const [agency, setAgency] = useState<IAgency>(null);

  const tryToFillAgentData = () => {
    if (populateData) {
      setInputs({
        ...inputs,
        firstName: {
          value: populateData.firstName || '',
          valid: true,
          touched: false,
        },
        lastName: {
          value: populateData.lastName || '',
          valid: true,
          touched: false,
        },
        address: {
          value: populateData.address || '',
          valid: true,
          touched: false,
        },
        building: {
          value: populateData.building || '',
          valid: true,
          touched: false,
        },
        city: { value: populateData.city || '', valid: true, touched: false },
        country: {
          value: populateData.country || '',
          valid: true,
          touched: false,
        },
        state: {
          value: populateData.state || '',
          valid: true,
          touched: false,
        },
        jobTitle: {
          value: populateData.jobTitle || '',
          valid: true,
          touched: false,
        },
        phoneNumber: {
          value: populateData.phoneNumber || '',
          valid: populateData.phoneNumber
            ? isPossiblePhoneNumber(populateData.phoneNumber)
            : true,
          touched: populateData.phoneNumber
            ? !isPossiblePhoneNumber(populateData.phoneNumber)
            : false,
        },
        postalCode: {
          value: populateData.postalCode || '',
          valid: true,
          touched: false,
        },
        expectedAmount: {
          value: populateData.expectedAmount || '',
          valid: true,
          touched: false,
        },
        hasNewsletterEnabled: {
          value: populateData.hasNewsletterEnabled || false,
          valid: true,
          touched: false,
        },
      });
      setAgency({
        ...populateData.agency,
        ...agency,
        pgId: populateData.agency ? populateData.agency.pgId : '',
      });
    }
  };

  const onInputChanged = (event: TextInputChangeEvent) => {
    const { name, value } = event.target;

    setInputs({
      ...inputs,
      [name]: {
        value,
        valid: inputValidator(name, value),
        touched: true,
      },
    });
  };

  const onNewsletterAccept = () => {
    setInputs({
      ...inputs,
      hasNewsletterEnabled: {
        value: !inputs.hasNewsletterEnabled.value,
        valid: true,
        touched: false,
      },
    });
  };

  const onConfirmClick = () => {
    setInputs({
      ...inputs,
      confirm: {
        valid: true,
        touched: true,
        value: !inputs.confirm,
      },
    });
  };

  const getCountries = () => {
    if (!featureFlags) {
      return [];
    }

    if (!agentValidValues || !agentValidValues.fields) {
      return [];
    }

    const countryField = agentValidValues.fields.find(
      (field) => field.name === 'MailingCountry'
    );
    if (!countryField) {
      return [];
    }
    return countryField.picklistValues;
  };

  const onFormSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const agentData = { ...populateData, agency } as IProfileData;
      const ignoreList: string[] = ['confirm'];
      Object.entries(inputs).forEach(([name, item]) => {
        if (ignoreList.includes(name)) return;
        Object.assign(agentData, { [name]: item.value });
      });

      try {
        agentData.phoneNumber = formatPhoneNumber(agentData.phoneNumber);
      } catch (err) {
        dispatch(
          createHeaderMessage(
            FAILED,
            translate('General_MessageFailed'),
            translate('MyProfile_EditProfile_FailedUpdate')
          )
        );
        return;
      }

      onSubmit(agentData);
    },
    [inputs]
  );

  useEffect(() => {
    dispatch(fetchLegal());
    if (!agentValidValues) {
      dispatch(fetchValidAgentValues());
    }
    tryToFillAgentData();
  }, []);

  useEffect(() => {
    if (!agentValidValues) return;

    tryToFillAgentData();
  }, [agentValidValues]);

  useEffect(() => {
    if (inputs === null) return;

    const exceptions: string[] = ['building'];
    if (region !== Region.Americas) {
      exceptions.push('expectedAmount');
    }
    if (region === Region.France) {
      exceptions.push('state');
    }
    if (!accountMigration) {
      exceptions.push('confirm');
    }
    setIsFormValid(
      Object.entries(inputs).every(
        ([name, item]) =>
          (item.valid && item.value !== '') || exceptions.includes(name)
      )
    );
  }, [inputs, setIsFormValid]);

  if (agentValuesLoading || inputs === null) {
    return (
      <div className={styles.loader}>
        <LoadingGif isGrey />
      </div>
    );
  }
  const JOB_TITLES = [
    {
      value: 'Administration',
      description: translate('Signup_Job_Administration'),
    },
    { value: 'Groups', description: translate('Signup_Job_Groups') },
    { value: 'Manager', description: translate('Signup_Job_Manager') },
    { value: 'Marketing', description: translate('Signup_Job_Marketing') },
    {
      value: 'Owner/President',
      description: translate('Signup_Job_OwnerPresident'),
    },
    { value: 'Travel Agent', description: translate('Signup_Job_TravelAgent') },
    {
      value: 'Vice President',
      description: translate('Signup_Job_VicePresident'),
    },
  ];
  const JOB_TITLES_GERMAN = [
    {
      value: 'Other employee',
      description: translate('Signup_Job_Other_Employee'),
    },
    { value: 'Manager', description: translate('Signup_Job_OfficeManager') }, // Büroleitung
    {
      value: 'Owner/President',
      description: translate('Signup_Job_OwnerPresident'), // inhaber
    },
    { value: 'Travel Agent', description: translate('Signup_Job_TravelAgent') }, // Expedient
    {
      value: 'Vice President',
      description: translate('Signup_Job_VicePresident'), // Geschäftsführer
    },
  ];

  return (
    <div className={styles.container} id="edit_user_container">
      <div className={styles.inputsContainer}>
        <TextInput
          id="signup_firstname"
          isRequired
          name="firstName"
          label={translate('EditUserData_FirstNameLabel')}
          placeholder={translate('EditUserData_FirstNamePlaceholder')}
          value={inputs.firstName.value}
          onChange={onInputChanged}
          helperText={
            !inputs.firstName.valid
              ? translate('EditUserData_FirstNameValidation')
              : null
          }
          error={!inputs.firstName.valid && inputs.firstName.touched}
        />
        <TextInput
          id="signup_lastname"
          isRequired
          name="lastName"
          label={translate('EditUserData_LastNameLabel')}
          placeholder={translate('EditUserData_LastNamePlaceholder')}
          value={inputs.lastName.value}
          onChange={onInputChanged}
          helperText={
            !inputs.lastName.valid
              ? translate('EditUserData_LastNameValidation')
              : null
          }
          error={!inputs.lastName.valid && inputs.lastName.touched}
        />
        <TextInput
          id="signup_address"
          isRequired
          name="address"
          label={translate('EditUserData_AddressLabel')}
          placeholder={translate('EditUserData_AddressPlacelholder')}
          value={inputs.address.value}
          onChange={onInputChanged}
          helperText={
            !inputs.address.valid
              ? translate('EditUserData_AddressValidation')
              : null
          }
          error={!inputs.address.valid && inputs.address.touched}
        />
        {featureFlags?.AgentFormBuilding && (
          <TextInput
            id="signup_building"
            name="building"
            placeholder={translate('EditUserData_BuildingPlaceholder')}
            label={translate('EditUserData_BuildingLabel')}
            value={inputs.building.value}
            onChange={onInputChanged}
          />
        )}
        <TextInput
          id="signup_city"
          isRequired
          name="city"
          placeholder={translate('EditUserData_CityPlaceholder')}
          label={translate('EditUserData_CityLabel')}
          value={inputs.city.value}
          onChange={onInputChanged}
          helperText={
            !inputs.city.valid
              ? translate('EditUserData_CityNameValidation')
              : null
          }
          error={!inputs.city.valid && inputs.city.touched}
        />
        <TextInput
          id="signup_postalcode"
          isRequired
          name="postalCode"
          placeholder={translate('EditUserData_ZipPlaceholder')}
          label={translate('EditUserData_ZipLabel')}
          value={inputs.postalCode.value}
          onChange={onInputChanged}
          helperText={
            !inputs.postalCode.valid
              ? translate('EditUserData_PostalCodeValidation')
              : null
          }
          error={!inputs.postalCode.valid && inputs.postalCode.touched}
        />
        <Dropdown
          id="signup_country"
          isRequired
          placeholder={translate('EditUserData_CountryPlaceholder')}
          label={translate('EditUserData_CountryLabel')}
          name="country"
          options={getCountries()}
          value={inputs.country.value}
          onChange={onInputChanged}
          helperText={
            !inputs.country.valid
              ? translate('EditUserData_CountryValidation')
              : null
          }
          error={!inputs.country.valid && inputs.country.touched}
        />
        {region !== Region.France && (
          <TextInput
            id="signup_region"
            isRequired
            name="state"
            placeholder={translate('EditUserData_FullStatePlaceholder')}
            label={translate('EditUserData_FullStateLabel')}
            value={inputs.state.value}
            onChange={onInputChanged}
            helperText={
              inputs.state && !inputs.state.valid
                ? translate('EditUserData_StateValidation')
                : null
            }
            error={inputs.state && !inputs.state.valid && inputs.state.touched}
          />
        )}
        <PhoneInput
          id="signup_phoneinput"
          isRequired
          label={translate('EditUserData_PhoneNumberLabel')}
          onChange={(phoneNumber) => {
            setInputs((prevInputs) => ({
              ...prevInputs,
              phoneNumber: {
                ...inputs.phoneNumber,
                value: phoneNumber,
                valid:
                  phoneNumber === undefined
                    ? false
                    : isPossiblePhoneNumber(phoneNumber),
                touched: true,
              },
            }));
          }}
          value={inputs.phoneNumber.value}
          error={!inputs.phoneNumber.valid && inputs.phoneNumber.touched}
          helperText={
            !inputs.phoneNumber.valid
              ? translate('EditUserData_PhoneNumberValidation')
              : null
          }
        />
        {region === Region.Americas && (
          <TextInput
            id="signup_expectedamount"
            isRequired
            name="expectedAmount"
            type="number"
            placeholder={translate('EditUserData_ExpectedAmountPlaceholder')}
            label={translate('EditUserData_ExpectedAmountLabel')}
            value={inputs.expectedAmount.value}
            onChange={onInputChanged}
            helperText={
              !inputs.expectedAmount.valid
                ? translate('EditUserData_ExpectedAmountValidation')
                : null
            }
            error={
              !inputs.expectedAmount.valid && inputs.expectedAmount.touched
            }
          />
        )}
        <Dropdown
          id="signup_jobtitle"
          placeholder={translate('EditUserData_JobTitlePlaceholder')}
          label={translate('EditUserData_JobTitleLabel')}
          name="jobTitle"
          options={
            region === Region.Germany || region === Region.Austria
              ? JOB_TITLES_GERMAN
              : JOB_TITLES
          }
          value={inputs.jobTitle.value}
          onChange={onInputChanged}
          helperText={
            !inputs.jobTitle.valid
              ? translate('EditUserData_JobTitleValidation')
              : null
          }
          error={!inputs.jobTitle.valid && inputs.jobTitle.touched}
        />
        {region !== Region.Americas && (
          <div className={styles.checkBoxContainer}>
            <Checkbox
              id="accept_newsletter"
              onChange={onNewsletterAccept}
              value={inputs.hasNewsletterEnabled.value?.toString()}
              defaultChecked={
                populateData?.hasNewsletterEnabled || region === Region.APACs
              }
              name="hasNewsletterEnabled"
              label={translate('EditUserData_WantsNewsletters')}
            />
          </div>
        )}
      </div>
      {accountMigration && (
        <div className={styles.checkBoxContainer}>
          <Checkbox
            id="accept_terms"
            onChange={onConfirmClick}
            value={inputs.confirm.value.toString()}
            label={translate('EditUserData_IHaveReadAndAgree')}
          />
          &nbsp;
          <button className={styles.termsButton} onClick={showModal}>
            {translate('EditUserData_TermsAndCondition')}
          </button>
        </div>
      )}
      <div className={styles.buttonGridItem}>
        <PrimaryButton
          id="signup_continue_btn"
          disabled={!isFormValid}
          onClick={onFormSubmit}
          className={styles.buttonGridItem}
        >
          {translate('General_Continue')}
        </PrimaryButton>
        <div className={styles.mandatoryField} />
      </div>
      {accountMigration && isModalVisible && (
        <RenderModal>
          <TermsModal onClose={hideModal} content={legal?.termsAndConditions} />
        </RenderModal>
      )}
    </div>
  );
};

export default RegisterUserData;
